import { memo } from 'react';
import styles from './AvatarUtterance.module.scss';
import { IMessageAvatarUtterance } from '../../../service/scenario/models/scenario';

interface IAvatarUtteranceProps {
  data: IMessageAvatarUtterance;
}

export const AvatarUtterance = memo(
  ({ data }: IAvatarUtteranceProps): JSX.Element => (
    <div className={styles.container}>
      <span>{data.params.display_text}</span>
    </div>
  ),
);

AvatarUtterance.displayName = 'AvatarUtterance';
