import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useChatContext } from '../../../contexts/ChatContext';
import { chatbotIdentityState } from '../../../states';

export const useChatControl = () => {
  const { branchSelection } = useChatContext();
  const { identity } = useRecoilValue(chatbotIdentityState);

  const handleSelect = useCallback(
    (choice: number, node_id: string, text: string) => {
      branchSelection({
        choice,
        node_id,
        text,
      })
        .then()
        // eslint-disable-next-line no-console
        .catch((e) => console.error(e));
    },
    [branchSelection],
  );

  return {
    handleSelect,
    user: identity.firebaseUuid,
  };
};
