/* eslint-disable max-lines-per-function */
import { useEffect, useRef } from 'react';
import styles from './ChatMessages.module.scss';
import { Scrollbar } from '../../atoms/ScrollBar';
import { Toast } from '../../atoms/Toast';
import { QuitConfirm } from '../../molecules/QuitConfirm';
import { TextInput } from '../../molecules/TextInput';
import { ButtonReset } from '../../atoms/ButtonReset';
import { useChatMessages } from './ChatMessages.hook';
import { ChatControl } from '../ChatControl';
import { EInstanceType } from '../../../service/scenario/models/scenario';

interface IChatMessages {
  isShowQuitConfirm: boolean;
  hideQuitConfirm: () => void;
}

export const ChatMessages = (props: IChatMessages): JSX.Element => {
  const { text, messages, hideChat, handleReset, handleChangeText, handleSend, lastMessageType } = useChatMessages();
  const { isShowQuitConfirm, hideQuitConfirm } = props;

  const scrollRef = useRef<HTMLDivElement>(null);
  const endOfItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfItemRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages.length]);

  return (
    <div className={styles.container}>
      <Scrollbar ref={scrollRef}>
        <div className={styles.content}>
          <div className={styles.chatMessages}>
            <div className={styles.toast}>
              <Toast text="自動対話を開始しました。" type="info" />
            </div>
            {messages.map((item, index) => (
              <div key={item.created_at + index.toString()}>
                <ChatControl message={item} nextMessage={messages[index + 1]} />
              </div>
            ))}
            <div ref={endOfItemRef} />
          </div>
        </div>
      </Scrollbar>

      {/* NOTE: 初回レンダリング時にfadeOutのアニメーションが流れるので表示をdelayさせている */}
      <div className={styles.delay}>
        <div className={lastMessageType === EInstanceType.end_of_scenario ? styles.fadeIn : styles.fadeOut}>
          <div className={styles.footer}>
            <div className={styles.reset}>
              <p className={styles.txtReset}>チャットを終了しました。</p>
              <ButtonReset label="はじめに戻る" onClick={handleReset} />
            </div>
          </div>
        </div>

        <div className={lastMessageType === EInstanceType.user_input ? styles.fadeIn : styles.fadeOut}>
          <div className={styles.footer}>
            <TextInput
              text={text}
              placeholder="入力することができます。"
              onChangeCallBack={handleChangeText}
              onSubmit={handleSend}
            />
          </div>
        </div>
      </div>

      <QuitConfirm
        filledButtonText="終了"
        hideChat={hideChat}
        hideQuitConfirm={hideQuitConfirm}
        isShow={isShowQuitConfirm}
        outlineButtonText="キャンセル"
        text="チャットを終了しますか？"
      />
    </div>
  );
};
