import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useChatContext } from '../../../contexts/ChatContext';
import { messageState, messageVariablesState } from '../../../states';

export const useChatMessages = () => {
  const { hideChat, inputResult, reset } = useChatContext();
  const [messageVariables, setMessageVariables] = useRecoilState(messageVariablesState);
  const messages = useRecoilValue(messageState);
  const [userMessage, setUserMessage] = useState('');
  const lastMessage = useMemo(() => messages[messages.length - 1], [messages]);
  const lastMessageType = useMemo(() => lastMessage?.instance_type, [lastMessage]);

  const handleChangeText = useCallback((value: string) => {
    setUserMessage(value);
  }, []);

  const handleSend = useCallback(() => {
    const { variables, currentSaveNameKey } = messageVariables;
    if (userMessage && currentSaveNameKey) {
      variables.set(`[${currentSaveNameKey}]`, userMessage);
      setMessageVariables({ ...messageVariables, variables });
      inputResult({
        node_id: lastMessage.node_id,
        result: userMessage,
      })
        .then()
        // eslint-disable-next-line no-console
        .catch((e) => console.error(e));

      setUserMessage('');
    }
  }, [inputResult, lastMessage, messageVariables, setMessageVariables, userMessage]);

  const handleReset = useCallback(() => {
    reset()
      .then()
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e));
  }, [reset]);

  return {
    text: userMessage,
    messages,
    hideChat,
    handleReset,
    handleChangeText,
    handleSend,
    lastMessageType,
  };
};
