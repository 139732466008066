import { useCallback, useState } from 'react';
import styles from './UserChoices.module.scss';
import { FixedButtonFill } from '../../atoms/FixedButtonFill';
import { IMessageUserChoices } from '../../../service/scenario/models/scenario';

export interface IUserChoicesProps {
  data: Pick<IMessageUserChoices, 'params' | 'node_id'>;
  onSelect: (choice: number, node_id: string, text: string) => void;
}

export const UserChoices = ({ data, onSelect }: IUserChoicesProps) => {
  const { choices } = data.params;
  const [isShow, setIsShow] = useState(true);

  const handleClick = useCallback(
    (choice: number, text: string) => {
      onSelect(choice, data.node_id, text);
      setIsShow(false);
    },
    [data.node_id, onSelect],
  );

  if (!isShow) return null;

  return (
    <div className={styles.container}>
      <div className={styles.text}>下記から選択してください</div>
      <div className={styles.buttonContainer}>
        {choices?.map((text, index) => (
          <div key={text} className={styles.choiceWrap}>
            <FixedButtonFill isElementCenter={false} label={text} onClick={() => handleClick(index, text)} />
          </div>
        ))}
      </div>
    </div>
  );
};

UserChoices.displayName = 'UserChoices';
