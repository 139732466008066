import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { EInstanceType, TMessage } from '../../../service/scenario/models/scenario';
import { messageState } from '../../../states';

export const useChatContainer = () => {
  const [isShowQuitConfirm, setIsShowQuitConfirm] = useState<boolean>(false);
  const [source, setSource] = useState('');
  const messages = useRecoilValue(messageState);

  const lastMessage: TMessage = useMemo(() => messages[messages.length - 1], [messages]);

  useEffect(() => {
    if (lastMessage?.instance_type === EInstanceType.avatar_utterance && lastMessage?.params?.gesture) {
      setSource(lastMessage.params.gesture);
    }

    if (lastMessage?.instance_type === EInstanceType.avatar_motion && lastMessage?.params?.gesture) {
      setSource(lastMessage.params.gesture);
    }
  }, [lastMessage]);

  const showQuitConfirm = () => {
    setIsShowQuitConfirm(true);
  };
  const hideQuitConfirm = () => {
    setIsShowQuitConfirm(false);
  };

  return {
    isShowQuitConfirm,
    showQuitConfirm,
    hideQuitConfirm,
    source,
  };
};
