import React, { memo } from 'react';
import { IMessageTextMessage } from '../../../service/scenario/models/scenario';
import styles from './UserTextMessage.module.scss';

interface IUserTextMessageProps {
  data: Pick<IMessageTextMessage, 'params'>;
}

export const UserTextMessage = memo(
  ({ data }: IUserTextMessageProps): JSX.Element => (
    <div className={styles.container}>
      <span>{data?.params?.display_text}</span>
    </div>
  ),
);

UserTextMessage.displayName = 'UserTextMessage';
