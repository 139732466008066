import React, { memo } from 'react';
import { IMessageTextMessage } from '../../../service/scenario/models/scenario';
import styles from './OperatorTextMessage.module.scss';

interface IOperatorTextMessageProps {
  data: Pick<IMessageTextMessage, 'params'>;
}

export const OperatorTextMessage = memo(
  ({ data }: IOperatorTextMessageProps): JSX.Element => (
    <div className={styles.container}>
      <span>{data.params.display_text}</span>
    </div>
  ),
);

OperatorTextMessage.displayName = 'OperatorTextMessage';
